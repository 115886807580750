const envConfig = {
  production: {
    APPHOST: 'hotmart.com/product',
    APP: 'https://hotmart.com',
    HOST: 'hotmart.com',
    ABTEST: 'https://api-abtest.hotmart.com/api/1/',
    ENDPOINT: 'https://api-display.hotmart.com/back',
    ENDPOINT_CDN: 'https://hotmart.com/back/api/display',
    ENDPOINT_INTERNAL: 'https://api-display.display.aws.hotmart/back',
    ASK_ENDPOINT: 'https://api-ask.hotmart.com',
    CAMPAIGN: 'https://dsp-cdn.hotmart.com',
    CHECKOUT: 'https://pay.hotmart.com',
    CHECKOUT_IP: 'https://api-ip.hotmart.com/api',
    CHECKOUT_PRICE: 'https://api-hotmart-async-checkout.hotmart.com/hotmart-checkout/api',
    CHECKOUT_LOAD: 'https://api-checkout-load.hotpay.aws.hotmart/api',
    CHECKOUT_VUE: 'https://api-checkout-vue.hotmart.com/api',
    PRICES: 'https://api-display-gateway.dsp.hotmart.com',
    DISPLAY_SEARCH_ENDPOINT: 'https://hotmart.com',
    HOTMART_HOME: 'https://hotmart.com',
    HOTLINK: 'https://go.hotmart.com',
    HUB_ENDPOINT: 'https://api-hub.hotmart.com',
    LOG: 'https://logstash.vulcano.rocks',
    ASSETS_PREFIX: 'product',
    PATH_UNDER_HOTMART_COM: '',// relative from hotmart.com/
    GOOGLE_API: 'http://maps.googleapis.com/maps/api/geocode',
    GOOGLE_API_KEY: 'AIzaSyDNS_G-hchSiPNj4XabAZ2m1BNc9wl44Zs',
    VLC: 'https://api-vlc.hotmart.com',
    OAUTH: 'https://api-sec-vlc.hotmart.com:443/security/rest/v1',
    CHECK_TOKEN: 'https://api-sec-vlc.hotmart.com:443/security/oauth',
    ROUTES_PREFIX: '',
    HELP: 'https://atendimento.hotmart.com.br/hc/pt-br',
    PROFILE: 'https://consumer.hotmart.com/my_account?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_account',
    PRODUCTS: 'https://consumer.hotmart.com/purchase?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_purchases',
    SUPER_PIXEL: 'https://pixel-lib.hotmart.com/pixel-integration.js',
    DISPLAY: 'https://api-display.hotmart.com/back/rest',
    DISPLAY_FRONT: 'https://display.hotmart.com',
    LOGIN: 'https://app-vlc.hotmart.com',
    PLATFORM: 'https://app-vlc.hotmart.com',
    TRANSPARENT_SCRIPT: 'https://pay.hotmart.com/assets/js/hotmart.transparent_checkout.js',
    STYLE_TRANSPARENT: 'https://hotmart.com/product/static/styles/checkout.css',
    MARKETPLACE: 'https://hotmart.com',
    INTEGRATION_COOKIE_PATH: '.hotmart.com',
    WWW_COOKIE_DOMAIN: 'hotmart.com',
    SENTRY_DSN: 'https://4f01ce581dac456d9d330cd5c577c00a@o49094.ingest.us.sentry.io/5193767',
    SERVICE_ONLINE: 'https://api-online-service.hotmart.com',
    LEAD_MASTERS: 'https://api-lead-capture.hotmart.com',
    LEAD_LAUNCH: 'https://handler.klicksend.com.br',
    LEAD_CAPTURE: 'https://api-display-microservices.hotmart.com',
    THUMBOR_URL: 'https://api-thumbor.hotmart.com/',
    CDN: 'https://static-media.hotmart.com/',
    AUTH_HOTMART: 'https://hotmart.com/auth',
    SSO_ENDPOINT: 'https://sso.hotmart.com/signup',
    GELF: {
      adapterName: 'tcp',
      adapterOptions: {
        host: 'logs.vulcano.rocks/_plugin/kibana',
        port: 12201
      }
    },
    REVIEWS: {
      QTY_PER_PAGE: 12
    },
    ANALYTICS: 'https://launcher.hotmart.com',
    CONTENT_ENDPOINT: 'https://api-content-search.dsp.hotmart.com'
  },
  staging: {
    APPHOST: 'buildstaging.com/product',
    AUTH_HOTMART: 'https://buildstaging.com/auth',
    APP: 'https://buildstaging.com',
    HOST: 'buildstaging.com',
    ENDPOINT: 'https://api-display.buildstaging.com/back',
    ENDPOINT_CDN: 'https://buildstaging.com/back/api/display',
    ENDPOINT_INTERNAL: 'https://api-display.buildstaging.aws.hotmart/back',
    ASK_ENDPOINT: 'https://api-ask.buildstaging.com',
    ABTEST: 'https://api-abtest.buildstaging.com/api/1/',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    CHECKOUT: 'https://app-hotmart-checkout.buildstaging.com',
    CHECKOUT_PRICE: 'https://api-hotmart-checkout.buildstaging.com/hotmart-checkout/api',
    CHECKOUT_LOAD: 'https://api-checkout-load.buildstaging.com/api',
    CHECKOUT_VUE: 'https://api-checkout-vue.buildstaging.com/api',
    PRICES: 'https://api-display-gateway.buildstaging.com',
    DISPLAY_SEARCH_ENDPOINT: 'https://api-display-search.buildstaging.com',
    HOTMART_HOME: 'https://buildstaging.com',
    HOTLINK: 'https://go.vulcano.rocks',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    CHECKOUT_IP: 'https://api-ip.hotmart.com/api',
    ASSETS_PREFIX: 'product',
    PATH_UNDER_HOTMART_COM: '',// relative from buildstaging.com/
    GOOGLE_API_KEY: 'AIzaSyDNS_G-hchSiPNj4XabAZ2m1BNc9wl44Zs',
    GOOGLE_API: 'http://maps.googleapis.com/maps/api/geocode',
    CHECK_TOKEN: 'https://api-security.buildstaging.com/security/oauth',
    VLC: 'https://api-marketplace.buildstaging.com',
    HELP: 'https://atendimento.hotmart.com.br/hc/pt-br',
    PROFILE: 'https://hot-hub-ui-stg.buildstaging.com/my_account?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_account',
    PRODUCTS: 'https://hot-hub-ui-stg.buildstaging.com/purchase?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_purchases',
    SUPER_PIXEL: 'https://pixel-lib.buildstaging.com/pixel-integration.js',
    ROUTES_PREFIX: '',
    DISPLAY: 'https://api-display.buildstaging.com/back/rest',
    DISPLAY_FRONT: 'https://app-display.buildstaging.com',
    LOGIN: 'https://app-vulcano.buildstaging.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    TRANSPARENT_SCRIPT: 'https://app-checkout-vue.buildstaging.com/assets/js/hotmart.transparent_checkout.js',
    STYLE_TRANSPARENT: 'https://buildstaging.com/static/styles/checkout.css',
    MARKETPLACE: 'https://buildstaging.com',
    INTEGRATION_COOKIE_PATH: '.buildstaging.com',
    WWW_COOKIE_DOMAIN: 'buildstaging.com',
    SENTRY_DSN: 'https://4f01ce581dac456d9d330cd5c577c00a@o49094.ingest.us.sentry.io/5193767',
    SERVICE_ONLINE: 'https://api-online-service.buildstaging.com',
    LEAD_MASTERS: 'https://api-lead-capture.buildstaging.com',
    LEAD_LAUNCH: 'https://handler.klicksend.com.br',
    LEAD_CAPTURE: 'https://api-display-microservices.buildstaging.com',
    THUMBOR_URL: 'https://api-thumbor.buildstaging.com/',
    CDN: 'https://static-media.buildstaging.com/',
    SSO_ENDPOINT: 'https://sso.buildstaging.com/signup',
    GELF: {
      adapterName: 'tcp',
      adapterOptions: {
        host: 'logstash.buildstaging.com',
        port: 12201
      }
    },
    REVIEWS: {
      QTY_PER_PAGE: 12
    },
    ANALYTICS: 'https://api-launcher.buildstaging.com',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com'
  },
  development: {
    APPHOST: 'localhost:3001/product',
    APP: 'http://localhost:3001',
    HOST: 'localhost',
    AUTH_HOTMART: 'https://local.app-hotmart-auth.buildstaging.com:3000/auth',
    ENDPOINT: 'https://api-display.buildstaging.com/back',
    ENDPOINT_CDN: 'https://buildstaging.com/back/api/display',
    ENDPOINT_INTERNAL: 'https://api-display.buildstaging.aws.hotmart/back',
    ASK_ENDPOINT: 'https://api-ask.buildstaging.com',
    ABTEST: 'https://api-abtest.buildstaging.com/api/1/',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    CHECKOUT: 'https://app-hotmart-checkout.buildstaging.com',
    CHECKOUT_PRICE: 'https://api-hotmart-checkout.buildstaging.com/hotmart-checkout/api',
    CHECKOUT_LOAD: 'https://api-checkout-load.buildstaging.com/api',
    CHECKOUT_VUE: 'https://api-checkout-vue.buildstaging.com/api',
    PRICES: 'https://api-display-gateway.buildstaging.com',
    DISPLAY_SEARCH_ENDPOINT: 'https://api-display-search.buildstaging.com',
    HOTMART_HOME: 'https://buildstaging.com',
    HOTLINK: 'https://go.vulcano.rocks',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    CHECKOUT_IP: 'https://api-ip.hotmart.com/api',
    ASSETS_PREFIX: '',
    PATH_UNDER_HOTMART_COM: '',// relative from buildstaging.com/
    GOOGLE_API_KEY: 'AIzaSyDNS_G-hchSiPNj4XabAZ2m1BNc9wl44Zs',
    GOOGLE_API: 'http://maps.googleapis.com/maps/api/geocode',
    CHECK_TOKEN: 'https://api-security.buildstaging.com/security/oauth',
    VLC: 'https://api-marketplace.buildstaging.com',
    HELP: 'https://atendimento.hotmart.com.br/hc/pt-br',
    PROFILE: 'https://hot-hub-ui-stg.buildstaging.com/my_account?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_account',
    PRODUCTS: 'https://hot-hub-ui-stg.buildstaging.com/purchase?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_purchases',
    SUPER_PIXEL: 'https://pixel-lib.buildstaging.com/pixel-integration.js',
    ROUTES_PREFIX: '',
    DISPLAY: 'https://api-display.buildstaging.com/back/rest',
    DISPLAY_FRONT: 'https://app-display.buildstaging.com',
    LOGIN: 'https://app-vulcano.buildstaging.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    TRANSPARENT_SCRIPT: 'https://app-checkout-vue.buildstaging.com/assets/js/hotmart.transparent_checkout.js',
    STYLE_TRANSPARENT: 'https://buildstaging.com/static/styles/checkout.css',
    MARKETPLACE: 'https://buildstaging.com',
    INTEGRATION_COOKIE_PATH: 'localhost',
    WWW_COOKIE_DOMAIN: 'localhost',
    SENTRY_DSN: 'https://4f01ce581dac456d9d330cd5c577c00a@o49094.ingest.us.sentry.io/5193767',
    SERVICE_ONLINE: 'https://api-online-service.buildstaging.com',
    LEAD_MASTERS: 'https://api-lead-capture.buildstaging.com',
    LEAD_LAUNCH: 'https://handler.klicksend.com.br',
    LEAD_CAPTURE: 'https://api-display-microservices.buildstaging.com',
    THUMBOR_URL: 'https://api-thumbor.buildstaging.com/',
    CDN: 'https://static-media.buildstaging.com/',
    SSO_ENDPOINT: 'https://sso.buildstaging.com/signup',
    GELF: {
      adapterName: 'tcp',
      adapterOptions: {
        host: 'logs.buildstaging.com/_plugin/kibana',
        port: 12201
      }
    },
    REVIEWS: {
      QTY_PER_PAGE: 12
    },
    ANALYTICS: 'https://api-launcher.buildstaging.com',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com',
  },
  test: {
    APPHOST: 'hotmart.com/product',
    ENDPOINT: 'https://api-display.buildstaging.com/back',
    ENDPOINT_CDN: 'https://buildstaging.com/back/api/display',
    ASK_ENDPOINT: 'https://api-ask.buildstaging.com',
    ABTEST: 'https://api-abtest.buildstaging.com/api/1/',
    CAMPAIGN: 'https://dsp-cdn.buildstaging.com',
    CHECKOUT: 'https://app-hotmart-checkout.buildstaging.com',
    HOTLINK: 'https://go.vulcano.rocks',
    HUB_ENDPOINT: 'https://api-hub.buildstaging.com',
    ASSETS_PREFIX: '',
    PATH_UNDER_HOTMART_COM: '',
    GOOGLE_API_KEY: 'AIzaSyDNS_G-hchSiPNj4XabAZ2m1BNc9wl44Zs',
    GOOGLE_API: 'http://maps.googleapis.com/maps/api/geocode',
    CHECK_TOKEN: 'https://api-security.buildstaging.com/security/oauth',
    VLC: 'https://api-marketplace.buildstaging.com',
    HELP: 'https://atendimento.hotmart.com.br/hc/pt-br',
    PROFILE: 'https://consumer.hotmart.com/my_account?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_account',
    PRODUCTS: 'https://consumer.hotmart.com/purchase?itm_medium=internal&itm_source=mktplace&itm_campaign=all_traffic_first-access_button_my_purchases',
    DISPLAY: 'https://api-display.hotmart.com/back/rest',
    DISPLAY_FRONT: 'https://display.hotmart.com',
    LOGIN: 'https://app-vlc.hotmart.com',
    PLATFORM: 'https://app-vulcano.buildstaging.com',
    TRANSPARENT_SCRIPT: 'https://app-checkout-vue.buildstaging.com/assets/js/hotmart.transparent_checkout.js',
    MARKETPLACE: 'https://buildstaging.com',
    INTEGRATION_COOKIE_PATH: 'buildstaging.com',
    GELF: {
      adapterName: 'tcp',
      adapterOptions: {
        host: 'logs.buildstaging.com/_plugin/kibana',
        port: 12201
      }
    },
    ANALYTICS: 'https://api-launcher.buildstaging.com',
    CONTENT_ENDPOINT: 'https://api-content-search.buildstaging.com'
  },
  REVIEWS: {
    QTY_PER_PAGE: 12
  },
  analyzer: {
    ASSETS_PREFIX: ''
  }
};
const currentEnv = process.env.ENV_CONFIG || 'development';

module.exports = {
  currentEnv: currentEnv,
  envConfig: envConfig[currentEnv]
};
